import request from './request'

const stockApi = {
  csvDownload(query) {
    return request({
      url: 'stocks/csv-download',
      method: 'get',
      params: query,
      responseType: 'blob'
    })
  },
    changeStatusStock(data) {
        return request({
            url: 'stocks/change-status',
            method: 'post',
            data: data
        })
    },
    changeStatusStockByScanQR(data) {
        return request({
            url: 'stocks/change-status-by-scan-qr',
            method: 'post',
            data: data
        })
    },
    itemScanQR(query){
        return request({
            url: 'stocks/item-scan-qr/' + query.item_id,
            method: 'get',
            params: query
        })
    },
    generateQR(query) {
        return request({
            url: 'stocks/generate-qr',
            method: 'get',
            params: query,
            responseType: 'blob'
        })
    },
  import (data, progress){
    return request({
      url: 'stocks/import-excel',
      method: 'post',
      data: data,
      timeout: 3600000,
      onUploadProgress: progress
    })
  },
    getItemMasters (data){
        return request({
            url: 'item-masters/get-all',
            method: 'post',
            data: data,
            timeout: 600000
        })
    },
    getItemByFilter(data) {
        return request({
            url: 'stocks/item-by-filter',
            method: 'get',
            params: data
        })
    },
    uploadCsv(data) {
        return request({
            url: 'stocks/upload',
            method: 'post',
            data: data
        })
    }
}
export default stockApi
