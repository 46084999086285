<template>

  <CRow>
    <CCol col="12" xl="12">
      <CCard>
        <CCardHeader>
          CSVアップロード
        </CCardHeader>
        <CCardBody>
          <UploadExcelComponent
              :on-success="handleSuccess"
              :header-array="fields"
              :before-upload="beforeUpload"
              :example-file="exampleFile"
              :is-import="isImport"
              :is-disabled="isDisabled"
              style="margin-bottom: 30px"
              @import="handleImport" />
          <div v-if="loadingForm">
              <CProgress class="mt-1" show-value>
                  <CProgressBar
                          :value="progress"
                          color="success"
                  />
              </CProgress>
              <LoadingCompoment />
          </div>
          <div v-if="loading">
            <LoadingCompoment />
          </div>
          <div v-else :key="key">
            <CDataTable
              hover
              striped
              :items="tableData"
              :fields="fields"
              :items-per-page-select="{
                  label: '表示件数',
                  values: [10, 20, 50, 100, 200, 500],
              }"
              :items-per-page="200"
              pagination
            >
                <td slot="id" v-bind:class="{'bg-danger': !!errors[index] && errors[index].id}" slot-scope="{item, index}">
                    <CButton
                        class="btn-error"
                        v-c-tooltip="errors[index] && errors[index].id ? errors[index].id : ''"
                        variant='ghost'
                    >
                        {{item.id}}
                    </CButton>
                </td>
              <td slot="item_id" v-bind:class="{'bg-danger': !!errors[index] && errors[index].item_id}" slot-scope="{item, index}">
                <CButton
                    class="btn-error"
                    v-c-tooltip="errors[index] && errors[index].item_id ? errors[index].item_id : ''"
                    variant='ghost'
                >
                  {{item.item_id}}
                </CButton>
              </td>
              <td slot="arrived_at" v-bind:class="{'bg-danger': !!errors[index] && errors[index].arrived_at}"
                  slot-scope="{item, index}">
                <CButton
                    class="btn-error"
                    v-c-tooltip="errors[index] && errors[index].arrived_at ? errors[index].arrived_at : ''"
                    variant='ghost'
                >
                  {{ item.arrived_at }}
                </CButton>
              </td>
<!--              <td slot="stock_in_date" v-bind:class="{'bg-danger': !!errors[index] && errors[index].stock_in_date}" slot-scope="{item, index}">-->
<!--                <CButton-->
<!--                    class="btn-error"-->
<!--                    v-c-tooltip="errors[index] && errors[index].stock_in_date ? errors[index].stock_in_date : ''"-->
<!--                    variant='ghost'-->
<!--                >-->
<!--                  {{item.stock_in_date}}-->
<!--                </CButton>-->
<!--              </td>-->
<!--              <td slot="stock_out_date" v-bind:class="{'bg-danger': !!errors[index] && errors[index].stock_out_date}" slot-scope="{item, index}">-->
<!--                <CButton-->
<!--                    class="btn-error"-->
<!--                    v-c-tooltip="errors[index] && errors[index].stock_out_date ? errors[index].stock_out_date : ''"-->
<!--                    variant='ghost'-->
<!--                >-->
<!--                  {{item.stock_out_date || ''}}-->
<!--                </CButton>-->
<!--              </td>-->
<!--              <td slot="from_shop_id" v-bind:class="{'bg-danger': !!errors[index] && errors[index].from_shop_id}" slot-scope="{item, index}">-->
<!--                <CButton-->
<!--                    class="btn-error"-->
<!--                    v-c-tooltip="errors[index] && errors[index].from_shop_id ? errors[index].from_shop_id : ''"-->
<!--                    variant='ghost'-->
<!--                >-->
<!--                  {{item.from_shop_id || ''}}-->
<!--                </CButton>-->
<!--              </td>-->
              <td slot="shop" v-bind:class="{'bg-danger': !!errors[index] && errors[index].shop}" slot-scope="{item, index}">
                <CButton
                    class="btn-error"
                    v-c-tooltip="errors[index] && errors[index].shop ? errors[index].shop : ''"
                    variant='ghost'
                >
                  {{item.shop || ''}}
                </CButton>
              </td>
              <td slot="item_master_id" v-bind:class="{'bg-danger': !!errors[index] && errors[index].item_master_id}" slot-scope="{item, index}">
                <CButton
                    class="btn-error"
                    v-c-tooltip="errors[index] && errors[index].item_master_id ? errors[index].item_master_id : ''"
                    variant='ghost'
                >
                  {{item.item_master_id || ''}}
                </CButton>
              </td>
              <td slot="shelf_id" v-bind:class="{'bg-danger': !!errors[index] && errors[index].shelf_id}" slot-scope="{item, index}">
                <CButton
                    class="btn-error"
                    v-c-tooltip="errors[index] && errors[index].shelf_id ? errors[index].shelf_id : ''"
                    variant='ghost'
                >
                  {{item.shelf_id || ''}}
                </CButton>
              </td>
                <td slot="item_masters_size" v-bind:class="{'bg-danger': !!errors[index] && errors[index].item_masters_size}" slot-scope="{item, index}">
                    <CButton
                        class="btn-error"
                        v-c-tooltip="errors[index] && errors[index].item_masters_size ? errors[index].item_masters_size : ''"
                        variant='ghost'
                    >
                        {{item.item_masters_size || ''}}
                    </CButton>
                </td>
                <td slot="item_masters_color" v-bind:class="{'bg-danger': !!errors[index] && errors[index].item_masters_color}" slot-scope="{item, index}">
                    <CButton
                        class="btn-error"
                        v-c-tooltip="errors[index] && errors[index].item_masters_color ? errors[index].item_masters_color : ''"
                        variant='ghost'
                    >
                        {{item.item_masters_color || ''}}
                    </CButton>
                </td>
                <td slot="item_code" v-bind:class="{'bg-danger': !!errors[index] && errors[index].item_code}" slot-scope="{item, index}">
                    <CButton
                        class="btn-error"
                        v-c-tooltip="errors[index] && errors[index].item_code ? errors[index].item_code : ''"
                        variant='ghost'
                    >
                        {{item.item_code || ''}}
                    </CButton>
                </td>
                <td slot="item_masters_name" v-bind:class="{'bg-danger': !!errors[index] && errors[index].item_masters_name}" slot-scope="{item, index}">
                    <CButton
                        class="btn-error"
                        v-c-tooltip="errors[index] && errors[index].item_masters_name ? errors[index].item_masters_name : ''"
                        variant='ghost'
                    >
                        {{item.item_masters_name || ''}}
                    </CButton>
                </td>
                <td slot="item_masters_cost" v-bind:class="{'bg-danger': !!errors[index] && errors[index].item_masters_cost}" slot-scope="{item, index}">
                    <CButton
                        class="btn-error"
                        v-c-tooltip="errors[index] && errors[index].item_masters_cost ? errors[index].item_masters_cost : ''"
                        variant='ghost'
                    >
                        {{item.item_masters_cost_commas || ''}}
                    </CButton>
                </td>
                <td slot="item_price" v-bind:class="{'bg-danger': !!errors[index] && errors[index].item_price}" slot-scope="{item, index}">
                    <CButton
                        class="btn-error"
                        v-c-tooltip="errors[index] && errors[index].item_price ? errors[index].item_price : ''"
                        variant='ghost'
                    >
                        {{item.item_price_commas || ''}}
                    </CButton>
                </td>
                <td slot="item_masters_retail_price" v-bind:class="{'bg-danger': !!errors[index] && errors[index].item_masters_retail_price}" slot-scope="{item, index}">
                    <CButton
                        class="btn-error"
                        v-c-tooltip="errors[index] && errors[index].item_masters_retail_price ? errors[index].item_masters_retail_price : ''"
                        variant='ghost'
                    >
                        {{item.item_masters_retail_price_commas || ''}}
                    </CButton>
                </td>
                <td slot="pp_bs_code" v-bind:class="{'bg-danger': !!errors[index] && errors[index].pp_bs_code}" slot-scope="{item, index}">
                    <CButton
                        class="btn-error"
                        v-c-tooltip="errors[index] && errors[index].pp_bs_code ? errors[index].pp_bs_code : ''"
                        variant='ghost'
                    >
                        {{item.pp_bs_code || ''}}
                    </CButton>
                </td>
                <td slot="is_special" v-bind:class="{'bg-danger': !!errors[index] && errors[index].is_special}" slot-scope="{item, index}">
                    <CButton
                        class="btn-error"
                        v-c-tooltip="errors[index] && errors[index].is_special ? errors[index].is_special : ''"
                        variant='ghost'
                    >
                        {{item.is_special || ''}}
                    </CButton>
                </td>
                <td slot="status" v-bind:class="{'bg-danger': !!errors[index] && errors[index].status}" slot-scope="{item, index}">
                    <CButton
                        class="btn-error"
                        v-c-tooltip="errors[index] && errors[index].status ? errors[index].status : ''"
                        variant='ghost'
                    >
                        {{item.status || ''}}
                    </CButton>
                </td>
            </CDataTable>
          </div>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import {STOCK_STATUS} from "@/utils/constance";
import apiStockOther from '../../api/stockApi';
import UploadExcelComponent from '@/components/UploadExcel/index.vue';
const apiItemMasters = new ResourceApi('item-masters');
import Vue from "vue";
import ResourceApi from "@/api/resourceApi";
import {numberWithCommas} from "@/utils/utils";
export default {
  name: 'StocksImport',
  components: { UploadExcelComponent },
  data () {
    return {
      stockStatus: STOCK_STATUS,
      stocks: [],
      loading: false,
      loadingForm: false,
      itemMasters: [],
      listSelected: [],
      typeDataSelected: '',
      paginationData: {},
      page: 1,
      perPage: 30,
      tableData: [],
      errors: {},
      tableHeader: [],
      headerArray: [],
      exampleFile: {
        // path: '/csv/stocks-2_24_2021, 6_06_56 PM.csv',
        // path: '/csv/stock-03_23_2021.csv',
        path: '/csv/stocks-3_24_2021.csv',
        name: 'stocks.csv',
      },
      query: {page: this.page, per_page: this.perPage,},
      key: Math.random(),
      options: [{label: '入荷日', value: 2}, {label: '出荷日', value: 3}],
      optionsPerPage: [10, 20, 30, 40, 50, 100],
      // apiStocks: apiStocks,
      fields: [
        { key: 'id', label: 'ID' },
        { key: 'item_id', label: '商品ID' },
        // { key: 'stock_in_date', label: '*入荷日' },
        // { key: 'stock_out_date', label: '*出荷日' },
        // { key: 'from_shop_id', label: '*出荷店舗ID' },
        { key: 'arrived_at', label: '*入荷日' },
        { key: 'shop', label: '店舗' },
        { key: 'item_master_id', label: '*商品マスタID' },
        { key: 'shelf_id', label: '*棚ID' },
        { key: 'item_code', label: '品番コード'},
        { key: 'item_masters_name', label: '品名' },
        { key: 'item_masters_size', label: '製品サイズ名' },
        { key: 'item_masters_color', label: 'カラー名' },
        { key: 'item_masters_cost', label: '仕入原価' },
        { key: 'item_price', label: '*販売価格' },
        { key: 'item_masters_retail_price', label: 'プロパー上代' },
        { key: 'pp_bs_code', label: '*PP/BS' },
        { key: 'is_special', label: '*優待' },
        { key: 'status', label: '状態' },
      ],
      activePage: 1,
      progress: 0,
      isImport: true,
      isDisabled: true
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler (route) {
        if (route.query && route.query.page) {
          this.activePage = Number(route.query.page)
        }
      }
    }
  },
  created() {
      // this.getAllItemMasters()
  },
  methods: {
    async handleSuccess({results, header}) {
        if (header.error_header) {
            let mess = 'ヘッダー名が正しくありません';
            Vue.$toast.error(mess)
            return;
        }
      let itemMasterIds = [];
        let data = [];
      results.map((r) => {
        if(!itemMasterIds.includes(r.item_master_id)){
          itemMasterIds.push(r.item_master_id);
        }
        if(r.id || r.item_id ||
            r.item_master_id || r.shelf_id || r.shelf_id || r.arrived_at ||
            r.item_code || r.item_masters_name || r.item_masters_size ||
            r.item_masters_color || r.item_masters_cost || r.item_price ||r.item_masters_retail_price){
          data.push(r)
        }
      })
      this.getAllItemMasters(itemMasterIds, data)
      // this.DataTable = results;
        // this.tableData = await this.overrideItemMaster(results)
        this.tableHeader = header
        this.key = Math.random()
    },

    overrideItemMaster(itemMasters, results){
      results.map((r, index) => {
            itemMasters.map(i => {
                if(i.id == r.item_master_id){
                    results[index].item_code = i.item_code;
                    results[index].item_masters_name = i.name;
                    results[index].item_masters_size = i.size.name;
                    results[index].item_masters_color = i.color.color_code + ':' + i.color.name;
                    results[index].item_masters_cost = i.cost;
                    results[index].item_price = r.item_price ? r.item_price  : i.price;
                    results[index].item_masters_retail_price = i.retail_price;
                }
            })
          results[index].item_masters_cost_commas = numberWithCommas(r.item_masters_cost);
          results[index].item_price_commas = numberWithCommas(r.item_price);
          results[index].item_masters_retail_price_commas = numberWithCommas(r.item_masters_retail_price);
        })
        return results;
    },

    beforeUpload(file) {
      this.errors = {}
      return true
    },

    getAllItemMasters(data, results) {
      this.loading = true
      let listStr = data.join()
      apiStockOther.getItemMasters({list_id_str: listStr}).then(response => {
          let itemMasters = response.data;
        this.tableData = this.overrideItemMaster(itemMasters, results)
        apiStockOther.uploadCsv(this.tableData).then(response => {
          this.isDisabled = false
          if (response.data.errors.length) {
              const errors = {}
              response.data.errors.map((e) => {
                errors[e.line] = {}
                Object.keys(e.errors).map((error) => {
                  errors[e.line][error] = e.errors[error][0]
                })
              })
              this.errors = errors
              this.key = Math.random()
            }
        })  
      }).catch(error => {
      }).finally(() =>{
          this.loading = false
      })
    },

    handleImport() {
      this.loadingForm = true;
      apiStockOther.import(this.tableData,  event => {
          this.progress = Math.round((100 * event.loaded) / event.total);
      }).then(res => {
          const mess = 'インポートに成功しました。';
          Vue.$toast.success(mess)
        setTimeout(this.$router.back(), 2000)
      }).catch(err => {
        if (err.response && err.response.data && err.response.data.errors) {
          const errors = {}
          // console.log(2222, err.response?.data)
          err.response?.data.errors.map((e) => {
            errors[e.line] = {}
            Object.keys(e.errors).map((error) => {
              errors[e.line][error] = e.errors[error][0]
            })
          })
          this.errors = errors
          this.key = Math.random()
          let mess = 'インポートに失敗しました';
          if(err.response.data.error_first){
            mess = err.response.data.error_first;
          }
            Vue.$toast.error(mess)
          // console.log(1111, errors)
        }
      }).finally(() => {
          this.progress = 0
          this.loadingForm = false
      })
    },
  }
}
</script>

<style>
 .btn-error{
     min-width: 40px;
     min-height: 40px;
 }
</style>
